exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-courses-index-js": () => import("./../../../src/pages/courses/index.js" /* webpackChunkName: "component---src-pages-courses-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-lessons-index-js": () => import("./../../../src/pages/lessons/index.js" /* webpackChunkName: "component---src-pages-lessons-index-js" */),
  "component---src-pages-story-js": () => import("./../../../src/pages/story.js" /* webpackChunkName: "component---src-pages-story-js" */),
  "component---src-pages-talks-index-js": () => import("./../../../src/pages/talks/index.js" /* webpackChunkName: "component---src-pages-talks-index-js" */),
  "component---src-pages-the-art-of-immutable-architecture-js": () => import("./../../../src/pages/the-art-of-immutable-architecture.js" /* webpackChunkName: "component---src-pages-the-art-of-immutable-architecture-js" */),
  "component---src-templates-course-js": () => import("./../../../src/templates/course.js" /* webpackChunkName: "component---src-templates-course-js" */),
  "component---src-templates-lesson-js": () => import("./../../../src/templates/lesson.js" /* webpackChunkName: "component---src-templates-lesson-js" */),
  "component---src-templates-talk-js": () => import("./../../../src/templates/talk.js" /* webpackChunkName: "component---src-templates-talk-js" */)
}

